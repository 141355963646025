<template>
  <transition name="bottom-sheet-fade" appear>
    <div class="parent-container">
      <div v-if="isShow" class="bottom-sheet-container">
        <div class="bottom-sheet-background" @click="closeBottomSheet"></div>
        <div class="bottom-sheet-content">
          <div v-show="isShow">
            <div class="content-container">
              <div class="content-title">수료 조건 안내</div>
              <div class="content-subtitle">본 수료 기준은 회원님의 꾸준한 학습을 위한 장치로 만들어졌습니다.</div>
              <div class="gap-24">
                <div class="content-img">
                  <div v-for="(imageUrl, index) in img" :key="index">
                    <img :src="imageUrl" />
                  </div>
                </div>
                <div class="question-bx-container">
                  <div v-for="(item, index) in fqa" :key="index" class="question-bx">
                    <div class="question">{{ item.question }}</div>
                    <div class="answer">{{ item.answer }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-sheet-checkbutton-container">
              <button class="bottom-sheet-checkbutton" @click="closeBottomSheet">
                <div>확인</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      fqa: [
        {
          question: 'Q 정상 완료가 무슨 뜻인가요?',
          answer: '17분 이상 수업한 경우 수업이 정상 완료된 것으로 판단합니다. ',
        },
        {
          question: 'Q 모든 수업을 하루도 빠지지 않고 해야하나요?',
          answer: `1:1 회화 수업은 조기 완료 시에도 수료로 인정됩니다. 또한 하루 2회 이상 수업도 가능합니다.
            첨삭은 회화 수업 당일이 아니어도 진행 가능하며 첨삭권을 모두 소진하지 않더라도 과정별 정해진 횟수를 채우면 수료로 인정됩니다. `,
        },
        {
          question: 'Q 실력 향상 판단의 기준이 궁금해요!',
          answer:
            '작심 패키지의 수료 조건에 부합하나 회원님의 실력이 늘지 않았다고 판단하여 수강료 환불을 요청하셨을 경우, 1:1 글로벌 튜터와의 초기 수업 녹음본과 수료 시점의 후기 수업 녹음본을 비교 평가하여 판단합니다.',
        },
      ],
      img: [
        'https://res.tutoring.co.kr/res/images/img/plans/renew2023/img_mind_condition01.png',
        'https://res.tutoring.co.kr/res/images/img/plans/renew2023/img_mind_condition02.png',
        'https://res.tutoring.co.kr/res/images/img/plans/renew2023/img_mind_condition03.png',
      ],
    };
  },
  props: {
    isShow: Boolean,
  },
  methods: {
    closeBottomSheet() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.parent-container {
  position: relative; /* 부모 컨테이너를 상대적 위치로 설정 */
  z-index: 102; /* 부모 컨테이너의 z-index 값을 높임 */
}
.content-img {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.content-img img {
  width: 100%;
}
.content-title {
  color: var(--opacity-black, #121214);

  /* H2 */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
}
.question-bx {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.content-subtitle {
  color: var(--opacity-black-600, rgba(18, 18, 20, 0.6));

  /* Body2 */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.question {
  color: var(--opacity-black, #121214);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.28px;
}
.answer {
  color: var(--opacity-black-600, rgba(18, 18, 20, 0.6));
  /* Body2 */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.bottom-sheet-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
}
.question-bx-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.bottom-sheet-checkbutton-container {
  max-width: 1200px;
  display: flex;
  padding: 0px var(--spacing-m, 24px) var(--spacing-m, 24px) var(--spacing-m, 24px);
  background: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
}
.bottom-sheet-checkbutton {
  height: var(--height-l, 48px);
  padding: var(--spacing-xxs, 4px) var(--spacing-s, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs, 8px);
  flex: 1 0 0;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: var(--radius-m, 8px);
  background: var(--primary-600, #5d04d9);

  color: var(--opacity-white, #fff);

  /* H3 */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}
.gap-24 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.bottom-sheet-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 98 !important; /* 반투명한 배경의 z-index 값을 낮춤 */
}
.content-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 32px 24px 120px 24px;
  overflow-y: auto; /* 세로 스크롤을 추가하여 내용이 넘칠 때 스크롤 표시 */
  height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  flex: 1;
}
.bottom-sheet-content {
  max-width: 1200px;
  position: relative;
  width: 100%;
  height: 80vh;
  background: white; /* 내용의 배경을 흰색으로 설정 */

  border-radius: 10px;

  display: flex;
  flex-direction: column;

  z-index: 99 !important; /* content의 z-index 값을 높여 취소 버튼을 클릭 가능하게 함 */
}

.cancel-button {
  z-index: 100 !important; /* 취소 버튼의 z-index 값을 높여 클릭 가능하도록 함 */
}

.bottom-sheet-fade-enter-active,
.bottom-sheet-fade-leave-active {
  transition: opacity 0.5s;
}

.bottom-sheet-fade-enter,
.bottom-sheet-fade-leave-to {
  opacity: 0;
}
</style>
