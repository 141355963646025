<template>
  <!-- app nav -->

  <AppNav v-if="appView">작심 패키지</AppNav>

  <div class="promotion" :style="{ 'padding-top': appPadding, 'padding-bottom': appBottom }">
    <!-- <div
      v-if="isPurchaseClicked"
      class="common-modal common-modal--dimmed"
      id="modalCertificateClose"
      style="display: block"
    >
      <div class="common-modal__modal modal--purchase" v-click-outside="closePurchaseModal">
        <div class="common-modal__content">
          <div class="common-modal__description" v-html="purchaseErrorMessage"></div>
          <div class="common-modal__description--small">
            {{ purchaseErrorSubMessage }}
          </div>
        </div>

        <div class="common-modal__buttonWrap" v-if="puchaseErrorType === 'level'">
          <button type="button" class="common-modal__button" @click="closePurchaseModal">취소</button>
          <button type="button" class="common-modal__button" @click="goPurchasePage">확인</button>
        </div>
        <div class="common-modal__buttonWrap" v-if="puchaseErrorType === 'hasTicket'">
          <button type="button" class="common-modal__button" @click="closePurchaseModal">확인</button>
        </div>
      </div>
    </div> -->
    <div class="promotion__banner">
      <div class="inner">
        <img :src="imgUrls.img_mind_top" />
      </div>
      <div class="inner" :style="{ display: 'flex', gap: '8px', padding: '32px 20px 15px 20px' }">
        <img :src="imgUrls.img_mind_point01" />

        <img :src="imgUrls.img_mind_point02" />

        <img :src="imgUrls.img_mind_point03" />
      </div>
      <div class="inner">
        <img :src="imgUrls.img_common_sec01" />
      </div>

      <div class="inner">
        <img :src="imgUrls.img_mind_sec01" />
      </div>
    </div>

    <div>
      <bottom-sheet :isShow="isBottomSheetVisible" @close="closeBottomSheet" />
    </div>
    <section class="courses__container">
      <div class="course__info" id="course__info">
        <div class="inner">
          <div class="course__step">
            <div class="swiper-text">초보 과정 커리큘럼</div>
            <div class="course__special">
              <div class="course__slide__wrapper">
                <div class="course__slide">
                  <swiper
                    :slides-per-view="9"
                    :space-between="18"
                    :centeredSlides="true"
                    :slideToClickedSlide="true"
                    style="min-width: 2502px !important"
                  >
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course1"
                        :title="courseInfos.course1.title"
                        :paragraph="courseInfos.course1.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course2"
                        :title="courseInfos.course2.title"
                        :paragraph="courseInfos.course2.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course3"
                        :title="courseInfos.course3.title"
                        :paragraph="courseInfos.course3.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course4"
                        :title="courseInfos.course4.title"
                        :paragraph="courseInfos.course4.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course5"
                        :title="courseInfos.course5.title"
                        :paragraph="courseInfos.course5.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course6"
                        :title="courseInfos.course6.title"
                        :paragraph="courseInfos.course6.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course7"
                        :title="courseInfos.course7.title"
                        :paragraph="courseInfos.course7.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course8"
                        :title="courseInfos.course8.title"
                        :paragraph="courseInfos.course8.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course9"
                        :title="courseInfos.course9.title"
                        :paragraph="courseInfos.course9.paragraph"
                      />
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>

          <div class="course__step">
            <div class="swiper-text" style="margin-top: 23px">중급이상 과정 커리큘럼</div>
            <div class="course__special">
              <div class="course__slide__wrapper">
                <div class="course__slide">
                  <swiper
                    :slides-per-view="9"
                    :space-between="16"
                    :centeredSlides="true"
                    :slideToClickedSlide="true"
                    style="min-width: 2502px !important"
                  >
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course1_middle"
                        :title="courseInfos_middle.course1.title"
                        :paragraph="courseInfos_middle.course1.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course2_middle"
                        :title="courseInfos_middle.course2.title"
                        :paragraph="courseInfos_middle.course2.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course3_middle"
                        :title="courseInfos_middle.course3.title"
                        :paragraph="courseInfos_middle.course3.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course4_middle"
                        :title="courseInfos_middle.course4.title"
                        :paragraph="courseInfos_middle.course4.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course5_middle"
                        :title="courseInfos_middle.course5.title"
                        :paragraph="courseInfos_middle.course5.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course6_middle"
                        :title="courseInfos_middle.course6.title"
                        :paragraph="courseInfos_middle.course6.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course7_middle"
                        :title="courseInfos_middle.course7.title"
                        :paragraph="courseInfos_middle.course7.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course8_middle"
                        :title="courseInfos_middle.course8.title"
                        :paragraph="courseInfos_middle.course8.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course9_middle"
                        :title="courseInfos_middle.course9.title"
                        :paragraph="courseInfos_middle.course9.paragraph"
                      />
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <앺ㅇ -->

    <div class="inner">
      <img :src="imgUrls.img_mind_sec02" />
    </div>
    <div class="inner">
      <button class="detail-btn" @click="toggleBottomSheet">수료 조건 자세히 보기</button>
    </div>
    <div class="inner" style="margin-top: 23px">
      <PageCRMSurveyVue />
    </div>

    <section class="promotion-notice">
      <div class="notice__item">
        <div class="notice" v-for="(item, index) in promotionData.planGuide" :key="index">
          <div class="box" @click="toggleContent(index)">
            <div class="notice__title">{{ item.title }}</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              :class="{ 'rotate-arrow': showContentIndex === index }"
            >
              <path
                d="M12 7L8 11L4 7"
                stroke="#121214"
                stroke-opacity="0.6"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <ul v-if="showContentIndex === index" class="notice__list">
            <li v-for="(text, i) in sortNoticeText[index]" :key="i" class="list__item">
              <p v-html="text"></p>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!--맨아래 구매유도 버튼(계속 보이는거)-->
    <section class="challenge-cta" :class="{ 'ani-bottom-up': bottomCtaUp }" id="challengeCta">
      <div class="challenge-cta__wrap" :class="{ sheetOn: isMobileBottomSheetOpen }">
        <div class="wrapper">
          <button v-if="isMobileBottomSheetOpen" @click="purchasePlan()" type="button" class="bottom_tab">
            혜택 확인하기
          </button>
          <button @click="goToApply" type="button" class="fixed__button" v-if="!isMobileBottomSheetOpen">
            <div class="text-impact">
              초보
              <span class="text-blur">수강권 보기</span>
            </div>
          </button>
          <button @click="goToApplyMiddle" type="button" class="fixed__button" v-if="!isMobileBottomSheetOpen">
            <div class="text-impact">
              중급
              <span class="text-blur">수강권 보기</span>
            </div>
          </button>
        </div>
      </div>

      <article class="sheet" :class="{ 'is-show': isMobileBottomSheetOpen }" id="bottomUpSheet">
        <div class="sheet__wrap">
          <div class="btn" @click="closeBottomSheetTab(), trackingUserAction('chobo_package_footer_close')"></div>
          <Pr90DaysOptions
            @update="updateOptionIdx"
            :promotionData="promotionData"
            :choicedOptionIdx="choicedOptionIdx"
            :choicedOptionData="choicedOptionData"
            :isRecommendIdx="isRecommendIdx"
          />
        </div>
        <div class="dim" @click="closeBottomSheetTab(), trackingUserAction('chobo_package_footer_close')"></div>
      </article>
    </section>
  </div>
</template>

<script lang="ts">
import { AxiosResponse } from 'axios';
import vClickOutside from 'click-outside-vue3';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { storage, native } from '@/tools';
import api from '@/common/api';
import inappTools from '@/common/inappTools';
import tools from '@/common/tools';
import PageCRMSurveyVue from '@/components/PageCRMSurvey/PageCRMSurvey.vue';
import vImageLazyLoading from '../../common/directives/ImgLazyLoading';
import promotionMixins from '../../common/mixins/promotion';
import AppNav from '../../components/AppNav.vue';
import Accordion from './component/Accordion.vue';

import BottomSheet from '../../components/BottomSheet.vue';

export default {
  name: 'Pr90days',
  components: {
    AppNav,
    Swiper,
    SwiperSlide,
    // VideoCard,
    PageCRMSurveyVue,
    Accordion,

    BottomSheet,
  },
  directives: {
    clickOutside: vClickOutside.directive,
    'img-lazy-loading': vImageLazyLoading,
  },
  mixins: [promotionMixins],
  data() {
    return {
      showContentIndex: false,
      isBottomSheetVisible: false,
      imgUrls: {
        img_mind_point01: '//res.tutoring.co.kr/res/images/img/plans/renew2023/img_mind_point01.png',
        img_mind_point02: '//res.tutoring.co.kr/res/images/img/plans/renew2023/img_mind_point02.png',
        img_common_sec01: '//res.tutoring.co.kr/res/images/img/plans/renew2023/img_common_sec01.png',
        img_mind_point03: '//res.tutoring.co.kr/res/images/img/plans/renew2023/img_mind_point03.png',
        img_mind_sec01: '//res.tutoring.co.kr/res/images/img/plans/renew2023/img_mind_sec01.png',
        img_mind_sec02: '//res.tutoring.co.kr/res/images/img/plans/renew2023/img_mind_sec02.png',
        img_mind_top: '//res.tutoring.co.kr/res/images/img/plans/renew2023/img_mind_top.png',
        //
        PC_pt90_thum01: '//res.tutoring.co.kr/res/images/evt/202203/pt90/PC_pt90_thum01.jpg',
        pt90_title_img: '//res.tutoring.co.kr/res/images/evt/202203/pt90/po90_title_img.png',
        pt90_01: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_why.png',
        pt90_02: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_professor.png',
        pt90_03: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1about.png',
        pt90_04: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_light_1.png',
        pt90_05_title: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_05_title.png',
        pt90_05_1: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_05_1.png',
        pt90_05_2: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_05_2.png',
        pt90_05_3: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_05_3.gif',
        pt90_05_4: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_05_4.png',
        pt90_05_5: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_05_5.png',
        pt90_05_6: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_05_6.png',
        pt90_05_7: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_05_7.png',
        pt90_06_title: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_06_title.png',
        pt90_06_1: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_06_1.png',
        // 탑 배너 1200px
        pt90_banner_thumb_img_1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1top_w1600_rolling_01.png',
        pt90_banner_thumb_img_2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1top_w1600_rolling_02.png',
        pt90_banner_thumb_img_3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1top_w1600_rolling_03.png',
        pt90_banner_thumb_img_4: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1top_w1600_rolling_04.png',
        pt90_banner_thumb_img_5: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1top_w1600_rolling_05.png',
        pt90_banner_thumb_img_6: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1top_w1600_rolling_06.png',
        pt90_banner_thumb_img_8: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1top_w1600_rolling_08.png',
        // 탑 배너
        pt90_banner_img_1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1top_rolling_01.png',
        pt90_banner_img_2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1top_rolling_02.png',
        pt90_banner_img_3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1top_rolling_03.png',
        pt90_banner_img_4: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1top_rolling_04.png',
        pt90_banner_img_5: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1top_rolling_05.png',
        pt90_banner_img_6: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1top_rolling_06.png',
        pt90_banner_img_8: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1top_rolling_08.png',
        pt90_banner_desc: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1top_sum.png',
        // 커리큘럼
        pt90_cur_title: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_mtl_01.png',
        pt90_cur_img_2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_special_02.png',
        pt90_cur_img_3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_special_03.png',
        pt90_cur_img_4: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_special_04.gif',
        pt90_cur_img_6: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_special_06.png',
        pt90_cur_subtitle_1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_stl_01.png',
        pt90_cur_subtitle_2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_stl_02.png',
        pt90_cur_subtitle_3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_stl_03.png',
        pt90_cur_subtitle_4: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_stl_04.png',
        pt90_cur_subtitle_6: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_stl_06.png',
        // 수료조건
        pt90_com_title: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_inform.png',
        pt90_com_img_1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_inform_90day.png',
        pt90_com_img_2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_inform_180day.png',
        pt90_com_img_3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_inform_qa.png',
        pt90_price: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_pricing.png',
        // ALL in one
        pt90_allin_1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_allinone_01.png',
        pt90_allin_2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_allinone_02.png',
        pt90_allin_3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_allinone_03.png',
        pt90_allin_4: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_step.png',
        pt90_allin_5: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1chart_01.gif',
        // 차트
        pt90_chart_1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_chart_01.png',
        pt90_chart_2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_chart_01.gif',
        pt90_chart_3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_chart_02.gif',
        pt90_chart_4: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_chart_03.gif',
        pt90_chart_5: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_chart_04.gif',
        pt90_chart_6: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_chart_02.png',
        pt90_recommend: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_recomm.png',
        pt90_award: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_awards_2023.png',
        pt90_video_title: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_review_01.png',

        // 튜터
        pt90_certificate: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_certificate.png',
        pt90_vs_01: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1vs_01.png',
        pt90_vs_02: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1vs_02.png',
        pt90_tutor_title: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_tutor.png',
        pt90_tutor_01: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tutor_pro01.png',
        pt90_tutor_02: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tutor_pro02.png',
        pt90_tutor_03: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tutor_pro03.png',
        pt90_tutor_04: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tutor_pro04.png',
        pt90_tutor_05: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tutor_pro05.png',
        pt90_review_title: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_review_02.png',
        pt90_effect_img_1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_effect.png',
        course1_middle: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_01.png',
        course2_middle: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_02.png',
        course3_middle: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_03.png',
        course4_middle: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_04.png',
        course5_middle: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_05.png',
        course6_middle: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_06.png',
        course7_middle: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_07.png',
        course8_middle: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_08.png',
        course9_middle: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_09.png',
        // 예전 작심 90일
        course1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1course_01.png',
        course2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1course_02.png',
        course3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1course_03.png',
        course4: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1course_04.png',
        course5: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1course_05.png',
        course6: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1course_06.png',
        course7: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1course_07.png',
        course8: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1course_08.png',
        course9: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1course_09.png',
        //
        course11: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_01.png',
        course12: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_02.png',
        course13: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_03.png',
        course14: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_04.png',
        course15: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_05.png',
        course16: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_06.png',
        course17: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_07.png',
        course18: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_08.png',
        course19: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_09.png',
        pt90_08: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_08.png',
        pt90_08_1: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_08_1.png',
        pt90_08_2: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_08_2.png',
      },

      courseInfos: {
        course1: {
          title: '말하는 두려움 깨기',
          paragraph: [
            '자기소개',
            '당당히 대답하기 : Be 동사 의문문편',
            '길 안내하기',
            '위치 말하기 : 전치사',
            '현재시제 배우기 : 수일치 & 부정형편',
            '당당히 대답하기 : Do동사 의문문편',
            '패턴영어로 문장말하기 1',
            'Pick & Choose : Food',
            'Pick & CHoose : Movie',
            'Pick & Choose : Sport',
            '(셀프스터디) 에세이 쓰기 : 초급 코스 1',
          ],
        },
        course2: {
          title: '현재 시제 익히기',
          paragraph: [
            '현재 시제 배우기 : 현재시제 vs 현재진행형',
            '현재시제 마스터',
            '문장 완성 연습',
            '얼마나 자주하니?',
            '시간 말하기1',
            '시간 말하기2',
            '시간 말하기3',
            '시간 말하기 마스터',
            '버킷리스트 : 초급편',
            '왜지? 상상해봐요!',
            '(셀프스터디) 에세이 쓰기 : 초급 코스 2',
          ],
        },
        course3: {
          title: '풍부한 의사 전달',
          paragraph: [
            '조동사 표현 배우기',
            '공손하게 물어보고 제안하기',
            '미래 시제 배우기 : Will & Be Going To',
            '내일의 날씨를 알려드립니다!',
            'Many와 Much의 사이1',
            'Many와 Much의 사이2',
            '의문문 배우기 : Yes or No편',
            '한 문장 게임1',
            '한 문장 게임 2',
            '한 문장 게임 3',
            '(셀프스터디) 에세이 쓰기 : 초급코스 3',
          ],
        },
        course4: {
          title: '과거 시제와 비교급 마스터',
          paragraph: [
            '과거 시제 배우기 : 동사 변형편',
            '과거 시제 마스터',
            '나의 하루 소개하기',
            'A vs B : 과거로 돌아가기!',
            '날짜 말하기 1',
            '날짜 말하기 2',
            '최상급 말하기',
            '비교급 말하기',
            '비교하기 A or B',
            '스파이 찾기',
            '(셀프스터디) 에세이 쓰기 : 초급 코스 4',
          ],
        },
        course5: {
          title: '해외여행 준비하기',
          paragraph: [
            '수와 양 말하기',
            'Campus English : 친구 사귀기편',
            '해외에서 살아남기 : 밥 먹기편',
            '해외에서 살아남기 : 병원편',
            '해외에서 살아님기 : 은행편',
            '해외에서 살아남기 : 집 구하기편',
            '해외에서 살아남기 : 전자기기편',
            '튜달이와 우주탐험',
            '공항 영어 : 체크인편',
            '공항 영어 : 입국심사편',
            '(셀프스터디) 에세이 쓰기 : 초급 코스 5',
          ],
        },
        course6: {
          title: '핵심 패턴으로 말하기',
          paragraph: [
            '패턴영어로 문장말하기 2',
            '패턴영어로 문장말하기 3',
            '세 문장 게임 : 맞추기편 1',
            '패턴영어로 문장말하기 4',
            '패턴영어로 문장말하기 5',
            '세 문장 게임 : 맞추기편 2',
            '패턴영어로 문장말하기 7',
            '패턴영어로 문장말하기 8',
            '세 문장 게임 : 맞추기편 3',
            '세 문장 게임 1',
            '(셀프스터디) 에세이 쓰기 : 초급 코스 6',
          ],
        },
        course7: {
          title: '여러 문장으로 생각 표현하기',
          paragraph: [
            '패턴영어로 문장말하기 6',
            '패턴영어로 문장말하기 9',
            '패턴영어로 문장말하기 10',
            '세 문장 게임 2',
            '세 문장 게임 3',
            '원서 읽기 : 어린왕자 & 진저브래드맨편',
            '세 문장 게임 4',
            '세 문장 게임 5',
            '원서 읽기 : The Necklace',
            '세계 문화 소개하기',
            '(셀프스터디) 에세이 쓰기 : 초급 코스 7',
          ],
        },
        course8: {
          title: '관용 표현 배우기',
          paragraph: [
            '나는 누구일까요?',
            '영어 표현 : moon편',
            '영어 표현 : 시간편',
            'Definition Game',
            '영어 표현 : 색깔편',
            '영어 표현 : 동물편',
            '추석을 소개해줘 초급편!',
            '영어 표현 : line편',
            '영어 표현 : boat편',
            'Interview Your Tutor!',
            '(셀프스터디) 에세이 쓰기 : 초급 코스 8',
          ],
        },
        course9: {
          title: '비지니스 표현',
          paragraph: [
            '영어 표현 : 과일편',
            '영어 표현 : 신체부위편',
            'Business Chunk : How about~?',
            'Business Chunk : make sure',
            '영어 표현 : 숫자편',
            '영어 표현 : door편',
            'Business Chunk : I dis/agree with',
            "Business Chunk : I'm wondering if",
            '영어 표현 : table편',
            '영어 표현 : book편',
            '(셀프스터디) 에세이 쓰기 : 초급 코스 9',
          ],
        },
      },
      courseInfos_middle: {
        course1: {
          title: '말하는 두려움 깨기',
          paragraph: [
            'Dear diary : 표현편',
            '표현 정복하기 : Do',
            '표현 정복하기 : Have',
            '짧은 글짓기 1',
            '원어민 코스프레 : 인사 & 소개편',
            '원어민 코스프레 : 상태편',
            '짧은 글짓기 2',
            '오늘의 표현 시즌 1-1',
            '오늘의 표현 시즌 1-2',
            '짧은 글짓기 3',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 1',
          ],
        },
        course2: {
          title: '자연스러운 문장으로 말하기 2',
          paragraph: [
            '짧은 글짓기 4',
            '원어민 코스프레 : 날씨 & 계절편 1',
            '원어민 코스프레 : 장소편 1',
            '짧은 글짓기 5',
            '표현 정복하기 : Get',
            '표현 정복하기 : Take',
            '짧은 글짓기 6',
            '원어민 코스프레 : 성격편 1',
            '원어민 코스프레 : 감정편 1',
            '오늘의 표현 시즌 1-3',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 2',
          ],
        },
        course3: {
          title: '목적과 상황에 맞는 말하기',
          paragraph: [
            '상황별 표현 : 공항편',
            '병원에서 통하는 영어 : 내과편',
            '원어민 코스프레 : 맛 표현편',
            '상황별 표현 : 여행 중 돌발상황편',
            '원어민 코스프레: 다툼 & 화해편',
            '병원에서 통하는 영어 : 이비인후과편',
            '상황별 표현 : 대중교통편',
            '원어민 코스프레: 부탁 & 감사편',
            '상황별 표현 : 레스토랑편',
            '허니문 허니팁',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 3',
          ],
        },
        course4: {
          title: '정확한 의사소통을 위하여',
          paragraph: [
            '연결의 달인, 접속사 마스터',
            '한국인이 잘 틀리는 문법',
            '끝나지 않은 이야기, 완료시제로 말해봐요!',
            'Konglish? Enghlish! 1',
            'If I were... : 무인도에서 살아남기!',
            'Common Mistakes',
            '수동태 완전 정복',
            'Konglish English! 2',
            '좀 더 길게 말하고 싶을 땐, 관계대명사!',
            'Konglish? English! 3',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 4',
          ],
        },
        course5: {
          title: '문제 해결 능력 UP!',
          paragraph: [
            '단어 게임',
            'Make a colorful story',
            '상황별 표현 : 여행 리허설편',
            '주문을 말해봐',
            '내 인상의 책 : 중급편',
            '스피치 영어 : 비욘세편',
            '내가 더 좋아하는 것 말하기',
            'Do you believe in...? : Fantasy편',
            '영어 문장 만들기 : 스토리 제작소편',
            '밸런스 게임 : 가족 모임편',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 5',
          ],
        },
        course6: {
          title: '손안에서 떠나는 어학연수',
          paragraph: [
            '튜터와의 일상대화 : 대학생편',
            '해외연수 3개월 과정 1',
            '해외연수 3개월 과정 2',
            '원어민 코스프레 : 동의 & 비동의편',
            '해외연수 3개월 과정 3',
            '해외연수 3개월 과정 4',
            '원어민 코스프레 : 길 설명하기편',
            '해외연수 3개월 과정 5',
            '해외연수 3개월 과정 6',
            '원어민 코스프레 : 의견 말하기편',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 6',
          ],
        },
        course7: {
          title: '스피킹 시험 대비를 위하여',
          paragraph: [
            '그림 설명하기',
            '토익 스피킹 : 지문 읽기편',
            '토익 스피킹 : 사진 묘사편',
            '토익 스피킹 : 질문 응답편',
            '토익 스피킹 : 정보 제공편',
            '토익 스피킹 : 의견 제시편',
            '오픽 모의테스트 1 (3-4)',
            '오픽 모의테스트 1 (5-6)',
            '오픽 모의테스트 2 (3-4)',
            '오픽 모의테스트 2 (5-6)',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 7',
          ],
        },
        course8: {
          title: '비즈니스 영어 집중 코스',
          paragraph: [
            '튜터와의 일상대화 : 직장인편',
            '비지니스 : 일상편 1',
            '비지니스 : 전화편',
            '비지니스 : 미팅편 1',
            '비지니스 : 발표편 1',
            '비지니스 : 프로젝트 관리편 1',
            '비지니스 : 회사 물품편',
            '비지니스 : 호스팅편 1',
            '비지니스 : 출장편 1',
            '원어민 코스프레 : 여행중 협상 표현편',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 8',
          ],
        },
        course9: {
          title: '일상 대화의 달인',
          paragraph: [
            '튜터와의 일상대화 : 일상편',
            '알록달록 색깔들',
            '수고했어 오늘도',
            '스피치 영어 : BTS 방탄소년단편',
            '영어로 대화하기 : 줄임말 편',
            '프리토킹',
            '쇼퍼홀릭',
            '100가지 주제로 말하기 1',
            '100가지 주제로 말하기 2',
            '100가지 주제로 말하기 3',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 9',
          ],
        },
      },
      promotionData: { plans: [{ cp_idx: 0 }] } as any,

      isSampleClicked: false,
      isPurchaseClicked: false,

      purchaseErrorMessage: '',
      purchaseErrorSubMessage: '',
      purchaseErrorType: '',
      // 사용자가 선택한 플랜의 idx
      choicedOptionIdx: 6910 as Number,

      // 사용자가 선택한 플랜 데이터 저장 _ 옵션 상세 부분 및 구매 이동에서 사용
      choicedOptionData: {},
      isRecommendIdx: 0,
      curTab: 1,
      tabsFix: false,
      tabsFixTop: '0px',
      bottomCtaUp: false,

      counter: {
        word: 0,
        sentence: 0,
        time: 0,
      },

      isMobileBottomSheetOpen: false,

      thisWeekStart: '',
      thisWeekEnd: '',
      nextWeekStart: '',

      isCouponClicked: false,
      couponData: { plans: [{ cp_idx: 0 }] },
      isPopup: false,
      cuponPopupMessage: '쿠폰 다운로드 완료!',
      hasCouponIdx: [],
      isDownload: true,
      isPromotionLoading: true,
    };
  },

  created() {
    this.getPromotionData();
    this.getCouponData();
    this.goHome();
  },
  mounted() {
    // if (!inappTools.isInApp()) {
    // 	this.$router.go(-1);
    // }
    this.onTabFixHandlerOn();
    this.onTabChangeHandler();
    this.initBackToPromotionFromCounselingFlag();
    this.setCounterAni();
    this.setPurchaeDateInfo();
    if (localStorage.getItem('key')) {
      this.setTrackingUserAction();
    }
    // window.C.NativeTool.onBackPressed = () => {
    // 	native.close();
    // };
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onBottomSheetOn);
  },

  methods: {
    goHome() {
      if (!inappTools.isInApp()) {
        window.location.href = `${window.location.protocol}/v2/classApply`;
      }
    },
    toggleContent(index) {
      this.showContentIndex = this.showContentIndex === index ? null : index;
    },
    toggleBottomSheet() {
      this.isBottomSheetVisible = !this.isBottomSheetVisible;
    },
    closeBottomSheet() {
      this.isBottomSheetVisible = false;
    },
    setTrackingUserAction(): void {
      const device = inappTools.isInApp() ? 'APP' : 'WEB';
      api.post('/app/users/setTracking', { event_name: 'detail', page_name: 'pr90days', device_type: `${device}` });
    },
    // openSample(): void {
    //   this.isSampleClicked = !this.isSampleClicked;
    // },
    clickPopupButton() {
      this.isPopup = true;
      setTimeout(() => {
        this.isPopup = false;
      }, 2000);
    },
    showCouponModal(): void {
      this.isCouponClicked = !this.isCouponClicked;
      if (this.isCouponClicked) {
        this.trackingUserAction(`chobo_readytogo_coupon_down`);
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.pageYOffset}`;
        document.body.style.width = '100%';
      } else {
        this.trackingUserAction(`chobo_readytogo_Coupon_close`);
        document.body.style.overflow = 'unset';
        document.body.style.position = '';
        document.body.style.width = '';
      }
    },
    async getCouponData(): Promise<any> {
      this.couponData = await api.get('/app/etc/promotion/365low').then((response: AxiosResponse) => response.data);

      this.couponData.coupon.data.coupons.forEach((coupon) => {
        if (coupon.hasCoupon) {
          this.hasCouponIdx.push(coupon.idx);
        }
      });
      if (this.hasCouponIdx.length >= 4) {
        this.isDownload = !this.isDownload;
      }
    },

    async postCouponDownload(dcIdx: number, index: number): Promise<any> {
      this.trackingUserAction(`chobo_readytogo_Coupon_${index + 1}`);

      const userKey = localStorage.getItem('key');

      if (!userKey) {
        window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
      } else {
        await api
          .post(`/app/users/coupon/discount/${dcIdx}`, {})
          .then((response) => {
            if (response.result === 2000) {
              this.cuponPopupMessage = '쿠폰 다운로드 완료!';
              this.hasCouponIdx.push(dcIdx);
              this.clickPopupButton();
            } else if (response.result === 1000) {
              this.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
              this.clickPopupButton();
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },

    async postCouponDownloadAll(): Promise<any> {
      this.trackingUserAction(`chobo_readytogo_Coupon_all`);

      const userKey = localStorage.getItem('key');

      if (!userKey) {
        window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
      } else {
        if (this.hasCouponIdx.length >= 4) {
          this.isDownload = !this.isDownload;
          return;
        }
        await api
          .post(`/app/users/coupon/download/all`, { couponIdx: '1053,1054,1055,1056' })
          .then((response) => {
            if (response.result === 2000) {
              this.cuponPopupMessage = '쿠폰 다운로드 완료!';
              this.hasCouponIdx.push(1053, 1054, 1055, 1056);
              this.clickPopupButton();
            } else if (response.result === 1000) {
              this.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
              this.clickPopupButton();
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },

    openPurchaseModal(): void {
      this.isPurchaseClicked = true;
    },
    closePurchaseModal(): void {
      this.isPurchaseClicked = false;

      if (inappTools.isInApp()) {
        native.close();
      } else {
        this.$router.push('/');
      }
    },

    async getPromotionData(): Promise<any> {
      this.promotionData = await api
        .get('/app/etc/promotion', {
          channel: 8,
          promotionCode: '2022_90days',
        })
        .then((response: AxiosResponse) => response.data);
      const RecommendPlan = this.promotionData.plans.filter((data) => data.isRecommend);
      const RecommendIndex = this.promotionData.plans.findIndex((data) => data.isRecommend);
      this.isRecommendIdx = RecommendIndex;
      this.choicedOptionIdx = RecommendPlan[0].cp_idx;
      this.choicedOptionData = { ...RecommendPlan[0] };
      this.isPromotionLoading = false;
    },

    openBottomSheetTab() {
      this.isMobileBottomSheetOpen = !this.isMobileBottomSheetOpen;
      // document.body.style.overflow = "hidden";
      // document.body.style.overscrollBehavior = "none";
    },

    goToApply() {
      this.trackingUserAction('chobo_package_footer');
      this.$router.push({ name: 'planAllPass', params: { prevPage: 'Pr90days' } });
    },
    goToApplyMiddle() {
      this.trackingUserAction('mid_package_footer');
      this.$router.push({ name: 'planAllPass', params: { prevPage: 'Pr90daysMiddle' } });
    },
    closeBottomSheetTab() {
      this.isMobileBottomSheetOpen = !this.isMobileBottomSheetOpen;
      // document.body.style.overflow = "unset";
      // document.body.style.overscrollBehavior = "";
    },

    updateOptionIdx(Id) {
      this.choicedOptionIdx = Id;
    },

    pushToLogin() {
      if (!tools.isLoggedIn()) {
        window.location.href = '/home/login';
      }
    },

    goPurchasePage() {
      const selectedPlan = this.promotionData.plans.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0];
      storage.session.set('goodsForOrder', selectedPlan);
      if (inappTools.isInApp()) {
        native.close();
      } else {
        const url = `https://${document.location.host}/home/order?&ptype=Kr&courseIdx=undefined`;
        window.location.href = url;
      }
    },
    purchasePlan() {
      const copyLoggedInUser = storage.session.get('loggedInUser');
      if (copyLoggedInUser) {
        const {
          lms: { s_level: userLevel },
          goods: { show2022_90Days: userHasNoTicket },
        } = copyLoggedInUser;

        if (!userHasNoTicket) {
          this.puchaseErrorType = 'hasTicket';
          this.purchaseErrorMessage = '이 상품은 현재 수업 진행중인 수강권이 없는 회원을 위한 상품입니다.';
          this.openPurchaseModal();
        } else if (Number(userLevel) > 4) {
          this.puchaseErrorType = 'level';
          this.purchaseErrorMessage = `
						이 상품은 <b>입문·초급 레벨</b>을 위한 상품이며,<br />
						현재 회원님은 <b>중급 레벨</b> 이상입니다.<br />
						그럼에도 불구하고, 해당 상품 구매를 원하신다면<br />
						<b>‘확인’</b> 버튼을 눌러주세요.`;
          this.purchaseErrorSubMessage = `수강 관련하여 학습 상담팀에서 유선 연락을 드릴 예정입니다.`;
          this.openPurchaseModal();
        } else {
          this.trackingUserAction('chobo_package_footer_payment');
          this.goPurchasePage();
        }
      } else {
        this.pushToLogin();
      }
    },
    initBackToPromotionFromCounselingFlag() {
      // 페이지 마운트 되었을 때 프로모션에서 상담예약으로 넘어갔는지 여부를 지워버리는 함수.
      // 만약 이전에 스크롤된 기록이 있다면, 그 위치로 스크롤 넘겨버림.
      const lastScrollOffset = storage.session.get('pr90DaysLastScrollOffset');
      if (lastScrollOffset) {
        setTimeout(() => {
          window.scrollTo(0, lastScrollOffset);
        });
      }
      storage.session.remove('backToPromotionUrlFromCounseling');
      storage.session.remove('pr90DaysLastScrollOffset');
    },
    moveToCounseling(name) {
      // 상담예약 버튼 눌렀을 때 그 인앱 페이지로 연결시키는 함수 (프로모션에서 넘어갔음을 플래그로 저장)
      this.trackingUserAction(name);
      // 인앱일 때 (웹일 때는 버튼 자체가 없음)
      if (inappTools.isInApp()) {
        const lastScrollOffset = document.documentElement.scrollTop;
        storage.session.set('routeAfterWebViewClosed', '/app/crm?ref=more');
        storage.session.set('backToPromotionUrlFromCounseling', 'Pr90days');
        storage.session.set('pr90DaysLastScrollOffset', lastScrollOffset);
        native.close();
      }
    },

    onBottomSheetOn() {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 1000) {
          this.bottomCtaUp = true;
        } else {
          this.bottomCtaUp = false;
        }
      });
    },

    onChangeTab(idx) {
      this.curTab = idx;
    },
    onTabChangeHandler() {
      window.addEventListener('scroll', () => {
        const scrollLocation = document.documentElement.scrollTop;
        const startOffsetTabOne = document.getElementById('course__info')?.offsetTop;
        const endOffsetTabOne = document.getElementById('course_learn')?.offsetTop;
        const startOffsetTabTwo = document.getElementById('course__complete')?.offsetTop;
        const endOffsetTabTwo = document.getElementById('differ')?.offsetTop;
        const startOffsetTabThree = document.getElementById('course__price')?.offsetTop;
        const endOffsetTabThree = document.getElementById('package')?.offsetTop;

        if (
          startOffsetTabOne &&
          endOffsetTabOne &&
          startOffsetTabTwo &&
          endOffsetTabTwo &&
          startOffsetTabThree &&
          endOffsetTabThree
        ) {
          if (scrollLocation > startOffsetTabOne - 95 && scrollLocation < endOffsetTabOne - 95) {
            this.curTab = 1;
          } else if (scrollLocation > startOffsetTabTwo - 95 && scrollLocation < endOffsetTabTwo - 95) {
            this.curTab = 2;
          } else if (scrollLocation > startOffsetTabThree - 95 && scrollLocation < endOffsetTabThree - 95) {
            this.curTab = 3;
          }
        }
      });
    },
    onTabFixHandlerOn() {
      window.addEventListener('scroll', () => {
        const scrollLocation = document.documentElement.scrollTop;
        const startOffset = document.getElementById('course__info')?.offsetTop;
        if (startOffset) {
          if (inappTools.isInApp()) {
            if (scrollLocation > startOffset - 95) {
              this.tabsFix = true;
              this.tabsFixTop = '35px';
            } else {
              this.tabsFix = false;
            }
          }
          // 웹
          else if (window.innerWidth > 1199) {
            if (scrollLocation > startOffset - 150) {
              this.tabsFix = true;
              this.tabsFixTop = '136px';
            } else {
              this.tabsFix = false;
            }
          }
          // 웹 이하
          else if (scrollLocation > startOffset - 80) {
            this.tabsFix = true;
            this.tabsFixTop = '71px';
          } else {
            this.tabsFix = false;
          }
        }
      });
    },
    pushToVideo() {
      if (inappTools.isInApp()) {
        native.openWebView('https://www.youtube.com/embed/Z9FzlPSenyg', '작심 90일 패키지');
      } else {
        window.open('https://www.youtube.com/embed/Z9FzlPSenyg');
      }
    },
    guaranteeSectionOffset() {
      if (window.innerWidth > 1199) {
        return 195;
      }
      return 95;
    },
    courseTabChoiceSectionOffset() {
      if (window.innerWidth > 1199) {
        return 140;
      }
      return 65;
    },
    doCounterAni() {
      const EndTime = 800;
      const wordCounterSpeed = 47070 / EndTime;
      const sentenceCounterSpeed = 7200 / EndTime;
      const timeCounterSpeed = 630 / EndTime;

      const wordCounter = setInterval(() => {
        this.counter.word += wordCounterSpeed;
        if (this.counter.word >= 47070) {
          this.counter.word = 47070;
          clearInterval(wordCounter);
        }
      }, 1);

      const sentenceCounter = setInterval(() => {
        this.counter.sentence += sentenceCounterSpeed;
        if (this.counter.sentence >= 7200) {
          this.counter.sentence = 7200;
          clearInterval(sentenceCounter);
        }
      }, 1);

      const timeCounter = setInterval(() => {
        this.counter.time += timeCounterSpeed;
        if (this.counter.time > 630) {
          this.counter.time = 630;
          clearInterval(timeCounter);
        }
      }, 1);
    },
    setCounterAni() {
      const $counterSection = document.querySelector('#effect');
      if (window.IntersectionObserver && $counterSection) {
        const interSectionObserver = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 감지대상이 교차영역에 진입 할 경우
              this.doCounterAni();
              observer.unobserve($counterSection); // 카운터 시작 이후로 관찰 필요 없다.
            }
          });
        });
        interSectionObserver.observe($counterSection);
      } else {
        this.counter.word = 47070;
        this.counter.sentence = 7200;
        this.counter.time = 630;
      }
    },
    setPurchaeDateInfo() {
      const curDay = new Date();
      const curYear = curDay.getFullYear();
      const curMonth = curDay.getMonth();
      const curDate = curDay.getDate();
      const curDayofWeek = curDay.getDay();
      const weekStartDate = new Date(curYear, curMonth, curDate - curDayofWeek + 1);
      const weekEndDate = new Date(curYear, curMonth, curDate + (6 - curDayofWeek) + 1);
      const nextWeekStartDate = new Date(curYear, curMonth, curDate + (6 - curDayofWeek) + 2);
      this.thisWeekStart = `${weekStartDate.getMonth() + 1}/${weekStartDate.getDate()}`;
      this.thisWeekEnd = `${weekEndDate.getMonth() + 1}/${weekEndDate.getDate()}`;
      this.nextWeekStart = `${nextWeekStartDate.getMonth() + 1}/${nextWeekStartDate.getDate()}`;
    },
  },

  computed: {
    sortNoticeText() {
      return this.promotionData.planGuide.map((data) => data.contents.map((e) => e.text.replace(/\n|n/g, '<br />')));
    },
    appView() {
      return inappTools.isInApp();
    },
    appPadding() {
      if (inappTools.isInApp()) {
        return '45px';
      }
      return '0px';
    },
    appBottom() {
      if (inappTools.isInApp()) {
        return '100px';
      }
      return '0px';
    },
    exceptBnrScroll() {
      return !sessionStorage.getItem('lineBnrClose') ? 105 : 0;
    },
    hasCouponIdxArray() {
      return (idx) => this.hasCouponIdx.includes(idx);
    },
  },

  watch: {
    // 옵션 상세 영역에 뿌려줄 선택한 데이터 가공
    choicedOptionIdx() {
      this.choicedOptionData = {
        ...this.promotionData.plans.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0],
      };
    },
  },
};
</script>

<style lang="scss" scoped src="../../assets/newPromotions.scss"></style>
<style lang="scss" scoped>
.promotion {
  overflow: hidden;
}
.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toCounselingbtn {
  margin-top: 40px;
  width: 220px;
  height: 64px;
  background-image: url('//res.tutoring.co.kr/res/images/img/plans/challenge/btn_coach.png');
  background-size: cover;
  cursor: pointer;
  border-radius: 40px;
}
.toGuaranteeSectionBtn {
  width: 220px;
  height: 64px;
  background-image: url('//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_cta2.png');
  background-size: cover;
  cursor: pointer;
  border-radius: 40px;
}
.modal--purchase {
  width: 350px;
  .common-modal__description--small {
    margin-top: 18px;
  }
}

.promotion__banner {
  text-align: center;
}
/*프로모션 헤더(웹)*/
.promotion__header {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  margin-bottom: 130px;
  @media (max-width: 1199px) {
    & {
      display: none;
    }
  }

  & .thumbnail {
    width: 570px;
    height: 570px;
    margin-right: 46px;

    .slides {
      width: 100%;
      .swiper-container {
        padding-bottom: 27px;
      }
      .img {
        width: 100%;
        height: 100%;
      }
    }
  }

  & .info {
    max-width: 583px;
    width: 100%;
    & .textarea {
      text-align: left;
      line-height: 1;

      & .btn {
        height: 68px;
        font-weight: 700;
        font-size: 22px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        background: #6d11d6;
        border-radius: 10px;
        color: #fff;
        cursor: pointer;
      }
    }
    & .textarea__title {
      & h1 {
        font-size: 24px;
        font-weight: 700;
        padding-bottom: 16px;
      }
      & h2 {
        font-size: 20px;
        font-weight: 400;
        padding-bottom: 25px;
        border-bottom: 1px solid #ddd;
      }
    }
    & .textarea__option {
      padding-top: 21px;
    }
  }
}
.swiper-slide {
  justify-content: center;
}
.detail-btn {
  color: var(--opacity-white, #fff);
  max-width: 1000px;
  /* H4 */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  width: 90%;
  display: flex;
  border-radius: 8px;
  background: #ff5c00;
  height: var(--height-l, 48px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xxs, 4px);
  flex-shrink: 0;
}
.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
.why {
  display: flex;
  justify-content: center;
  background-color: #121214;

  & .inner {
    margin: 20px 0;
  }

  & img {
    max-width: 50%;
    min-width: 360px;
  }
}
.correct {
  display: flex;
  justify-content: center;
  background-color: #04d9ff;

  & .inner {
    margin: 20px 0;
  }

  & img {
    max-width: 50%;
    min-width: 360px;
  }
}
.rotate-arrow {
  transform: rotate(180deg); /* 원하는 각도로 조정 가능 */
  transition: transform 0.3s ease; /* 회전 애니메이션 효과 적용 가능 */
}
.banner__course {
  display: flex;
  justify-content: center;
  background-color: #300a61;

  & .inner {
    position: relative;
    margin: 80px 0 100px;
  }

  & .img1 {
    max-width: 50%;
    min-width: 360px;
  }
  & .blur {
    position: absolute;
    bottom: -100px;
    width: 100vw;
    height: 500px;
    background-position-x: 53%;
    background-position-y: 6%;
    background-image: url('https://res.tutoring.co.kr/res/images/img/plans/challenge/img_light_1.png');
  }
}
.course_learn {
  display: flex;
  justify-content: center;
  background-color: #04d9ff;

  & .inner {
    margin: 80px 0 100px;
    .allin_img {
      max-width: 50%;
      min-width: 360px;
    }
    .allin_video {
      margin-top: 30px;
      max-width: 50%;
      min-width: 360px;
    }
    .allin_desc {
      max-width: 50%;
      min-width: 360px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}

.course__chart {
  display: flex;
  justify-content: center;
  background-color: #ffffff;

  & .inner {
    margin: 80px 0 100px;

    .chart_title {
      max-width: 40%;
      min-width: 380px;
      margin-top: 60px;
    }
    .chart_img {
      max-width: 30%;
      min-width: 300px;
      margin-top: 60px;
    }
    .chart_desc {
      text-align: center;
      margin-top: 20px;
      font-size: 35px;
      font-weight: 700;
    }
  }
}
.swiper-text {
  color: var(--opacity-black, #121214);
  text-align: center;
  /* H2 */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  padding-top: 17px;
  padding-bottom: 16px;
}
.course__guarantee {
  background-color: #300a61;
  & .guarantee__main {
    display: flex;
    justify-content: center;

    & .inner {
      flex-direction: column;
      align-items: center;

      .certificate_img {
        max-width: 30%;
        min-width: 360px;
      }
    }
  }
  .course__complete {
    display: flex;
    justify-content: center;
    width: 100%;
    background: #f3f3f3;
    .inner_2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 80px 0 100px;
      img.cur_title {
        max-width: 40%;
        min-width: 380px;
        margin-bottom: 10px;
      }
      & img.cur_image_1 {
        max-width: 60%;
        min-width: 380px;
        margin-top: 20px;
      }
      & img.cur_image_2 {
        max-width: 60%;
        min-width: 380px;
        margin-top: 20px;
      }
    }
  }
}
.courses__container {
  padding-bottom: 16px;

  & .course__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;

    & img {
      margin: 100px 0 60px;
    }

    & .course__tab {
      display: flex;
      justify-content: center;
      width: 100%;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);

      & li {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        width: 164px;
        height: 56px;
        border-radius: 8px 8px 0 0;
        color: #afabb7;
        cursor: pointer;
      }

      & li.active {
        background-color: #fff;
        color: #6d11d6;
        .tabs {
          text-align: center;
          border-bottom: 3px solid #6d11d6;
          width: 61%;
          margin: auto;
          line-height: 52px;
        }
      }
    }
    & .course__tab.fixed {
      position: fixed;
      display: flex;
      justify-content: center;
      width: 100%;
      z-index: 10;
      box-shadow: 0px 2px 10px #121214;
      background-color: #fff;

      & li {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        width: 164px;
        height: 56px;
        border-radius: 8px 8px 0 0;
        color: #afabb7;
        cursor: pointer;
      }

      & li.active {
        background-color: #fff;
        color: #6d11d6;
        .tabs {
          text-align: center;
          border-bottom: 3px solid #6d11d6;
          width: 61%;
          margin: auto;
          line-height: 52px;
        }
      }
    }
  }

  & .course__info {
    display: flex;
    justify-content: center;
    /* background-color: #121214; */
    .inner {
      flex-direction: column;
      align-items: center;
      max-width: 30%;
      min-width: 400px;
      .main_img {
        width: 100%;
      }
      & .course__step {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        & img.subtitle {
          width: 100%;
        }
        & img.subimg {
          max-width: 380px;
          margin-top: 25px;
          border-radius: 10px;
        }
        & .subimg2 {
          margin-top: 25px;
          width: 330px;
          border-radius: 10px;
        }

        & .course__special {
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow: hidden;

          & .course__slide__wrapper {
            display: flex;
            justify-content: center;
            /* margin-top: 40px; */
            overflow: hidden;
            min-width: 2502px;
            & .course__slide {
              width: 2502px;
              min-width: 2502px;

              & .slide__card {
                border: 1px solid var(--opacity-black-100, rgba(18, 18, 20, 0.18));
                background-color: #fefefe;
                width: 262px;
                min-width: 262px;
                cursor: pointer;
                border-radius: 20px;
                overflow: hidden;
                @media only screen and (max-width: 768px) {
                  -webkit-box-sizing: border-box;
                }

                & img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.effect {
  display: flex;
  justify-content: center;
  background-color: #300a61;
  overflow: hidden;
  & .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 0;
    & img.effect1 {
      max-width: 50%;
      min-width: 360px;
    }
    & > p {
      margin-top: 8px;
      font-weight: 200;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
    }
    & .card__wrapper {
      width: 453px;
      position: relative;
      & img.card__background {
        width: 100%;
        height: 507px;
      }
      & .card {
        position: absolute;
        top: 67px;
        left: 50%;
        margin-left: calc(300px / -2);
        width: 300px;
        height: 373px;

        & ul {
          font-weight: 900;
          font-size: 36px;
          line-height: 42px;
          color: #121214;
          text-shadow: -0.3px 0 #121214, 0 0.3px #121214, 0.3px 0 #121214, 0 -0.3px #121214;
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          & li {
            margin-top: 20px;
            padding-bottom: 3px;

            & span {
              display: inline-block;
              text-align: end;
              color: #5d04d9;
              text-shadow: -0.3px 0 #5d04d9, 0 0.3px #5d04d9, 0.3px 0 #5d04d9, 0 -0.3px #5d04d9;

              &.counter--word {
                width: 120px;
              }
              &.counter--sentence {
                width: 100px;
              }
              &.counter--time {
                width: 65px;
                margin-right: -8px;
              }
            }
          }
        }

        & p {
          margin-top: 42px;
          text-align: center;
          font-weight: 700;
          font-size: 18px;
          line-height: 27px;
          color: #300a61;
        }
      }
    }
    & img.effect2 {
      max-width: 40%;
      min-width: 300px;
    }
  }
}
.differ {
  display: flex;
  justify-content: center;
  background-color: #333333;
  padding: 100px 0 40px;
  & img {
    max-width: 50%;
    min-width: 360px;
  }
}
.tutors {
  display: flex;
  justify-content: center;
  background-color: #300a61;

  & .inner {
    margin: 100px 0 70px;

    img.tutor_title {
      max-width: 40%;
      min-width: 380px;
      margin-bottom: 30px;
    }

    .slides {
      width: 360px;
      .swiper-container {
        padding-bottom: 27px;
      }
      .img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.course__price {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #371469;
  padding: 50px 0px;
  .inner {
    flex-direction: column;
    align-items: center;
    .title {
      padding: 50px 40px;
      &_text {
        margin-bottom: 5px;
        font-weight: 700;
        color: #ffffff;
        text-align: center;
      }
    }
    .price {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      &_select {
        background: #04d9ff;
        width: 25%;
        padding: 10px 0px;
        border-radius: 40px;
        color: #371469;
        font-size: calc(16px + 0.5vw);
        font-weight: 700;
        text-align: center;
        margin-bottom: 10px;
      }
      &_title {
        font-size: calc(14px + 0.5vw);
        font-weight: 700;
        color: #ffffff;
        margin-bottom: 20px;
      }
      &_container {
        display: flex;
        align-items: center;
        margin: 10px 0;
        justify-content: center;
      }
      &_content {
        position: relative;
        background: #ffffff;
        border-radius: 30px;
        padding: 40px 50px;
        text-align: center;
        &_discount {
          padding: calc(2px + 0.1vw) 8px;
          font-size: calc(14px + 0.2vw);
          font-weight: 600;
          border-radius: 8px;
          background: #f24b4b;
          color: #ffffff;
          margin-right: 8px;
        }
      }
      &_discount {
        font-size: calc(16px + 0.2vw);
        font-weight: 600;
      }
      &_month {
        font-size: calc(14px + 0.2vw);
      }
    }
  }
}
.award {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  padding: 20px 0;
  overflow: hidden;
  .award_img {
    max-width: 50%;
    min-width: 360px;
  }
}
.videos {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: #ffffff;
  padding: 100px 0;
  overflow: hidden;
  & .videos__title {
    max-width: 40%;
    min-width: 380px;
    margin-bottom: 10px;
  }
  & .videos__thumbnail {
    margin-top: 20px;
  }
  & .videos__subtitle {
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
}
.reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #5d04d9;
  padding: 100px 100px;
  overflow: hidden;
  & .reviews__title {
    max-width: 40%;
    min-width: 380px;
  }

  & .reviews__slide {
    width: 855px;
    margin-top: 40px;

    & .reviewcard {
      width: 275px;
      min-width: 275px;
      height: 200px;
      background: #ffffff;
      border-radius: 15px;
      padding: 25px;
      box-sizing: border-box;
      cursor: pointer;
      @media only screen and (max-width: 768px) {
        -webkit-box-sizing: border-box;
      }
    }

    & .reviewcard__title {
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.594203px;
      color: #333333;
    }

    & .reviewcard__body {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.594203px;
      color: #555555;
      margin-top: 13px;
    }

    & .reviewcard__writer {
      display: flex;
      justify-content: space-between;
      margin-top: 13px;

      & .writer__name {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.594203px;
        color: #333333;
      }

      & .writer__score {
        display: flex;
        align-items: center;
        & .score__stars {
          width: 82px;
          height: 16px;
          background: url(//res.tutoring.co.kr/res/images/common/ico_comm.png) no-repeat -124px -84px;
          background-size: 220px 188px;
          background-position: -38px -84px;
        }
        & .score__text {
          margin-left: 5px;
          font-weight: 700;
          font-size: 14px;
          line-height: 14px;
          color: #333333;
        }
      }
    }
  }

  & ul {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.package {
  & .package__info {
    background-color: #5d04d9;
    padding: 20px 0 70px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .recommend_img {
      max-width: 50%;
      min-width: 360px;
    }
  }
}
/* 구매하기 cta */
.challenge-cta {
  position: fixed;
  bottom: -100%;
  right: 0;
  left: 0;
  z-index: 50;
  transition: bottom 0.4s;
  bottom: 0;
  background-color: white;

  & .challenge-cta__wrap {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* background: rgba(0, 0, 0, 0.5); */
    backdrop-filter: blur(30px);
    height: 84px;
    @media (max-width: 1199px) {
      & {
        height: 98px;
        /* padding: 0 20px; */
      }
    }
    &.sheetOn {
      background: #fff;
    }
    & .wrapper {
      max-width: 1000px;
      display: flex;
      width: 100%;
      padding: var(--spacing-s, 16px) 24px;
      flex: 1 0 0;
      justify-content: center;
      align-items: center;
      gap: 8px;

      & button {
        display: flex;
        height: 56px;
        padding: var(--spacing-s, 16px);
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        border-radius: 10px;
        background: var(--primary-600, #5d04d9);

        @media (max-width: 1199px) {
          & {
            font-size: 15px;
            letter-spacing: -0.5px;
          }
        }
      }
    }
  }
}

.sheet {
  position: absolute;
  bottom: -1000px;
  width: 100%;
  color: #000;
  transition: all 0.4s;
  z-index: -1;

  & .dim {
    display: none;
  }

  &.is-show {
    position: fixed;
    bottom: 84px;
    transition: all 0.4s;

    & .dim {
      display: block;
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      background-blend-mode: multiply;
      z-index: -1;
    }
  }

  & .sheet__wrap {
    border-radius: 10px 10px 0 0;
    background: #fff;
    padding: 0 20px;

    & .option {
      height: 70vh;
      overflow-y: scroll;
    }

    & .btn {
      position: relative;
      height: 50px;
      cursor: pointer;

      &:after {
        position: absolute;
        top: 30%;
        right: 0;
        left: 0;
        width: 10px;
        height: 10px;
        margin: auto;
        border-top: 2px solid #333;
        border-right: 2px solid #333;
        vertical-align: middle;
        box-sizing: border-box;
        transform: rotate(135deg) translateY(-50%);
        content: '';
      }
    }
  }
}

// 쿠폰 다운로드
.coupon-download-contaienr {
  background-color: #fff;
  padding: 20px 20px 50px 20px;
}
.coupon-download-contaienr .coupon-download-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px #5d04d9;
  border-radius: 20px;
  height: 96px;
  margin: 0 auto 10px auto;
  cursor: pointer;
}
@media (max-width: 767px) {
  .coupon-download-contaienr .coupon-download-box {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .coupon-download-contaienr .coupon-download-box {
    width: 530px;
  }
}
.coupon-download-contaienr .coupon-download-box .box {
  display: flex;
  align-items: center;
}
.coupon-download-contaienr .coupon-download-box .box .left-img {
  width: 50px;
  height: 41px;
  background-image: url('//res.tutoring.co.kr/res/images/img/plans/ic_plan_coupon.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50px 41px;
  margin-right: 20px;
  margin-left: 25px;
}
.coupon-download-contaienr .coupon-download-box .text .desc {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.5px;
  color: #afabb7;
  margin-bottom: 5px;
}
.coupon-download-contaienr .coupon-download-box .text .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -1px;
  color: #121214;
}
.coupon-download-contaienr .coupon-download-box .download-img {
  width: 46px;
  height: 46px;
  background-image: url('//res.tutoring.co.kr/res/images/img/plans/btn_down.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 46px 46px;
  margin-right: 25px;
}
.coupon-download-contaienr .coupon-download-box .download-done {
  width: 96px;
  height: 100%;
  background: #efe6fb;
  border-radius: 0 20px 20px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: rgba(93, 4, 217, 0.5);
  padding: 32px 35px;
}
.coupon-download-contaienr .desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #afabb7;
}
.layer_comm {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.layer_comm .dimmed {
  width: 100%;
  height: 100%;
  background: #1a1a1a;
  opacity: 0.3;
}

.layer_comm .inner_wrapper {
  position: absolute;
  top: 54%;
  right: 0;
  left: 0;
  width: 348px;
  /* height: 100%; */
  margin: 0 auto;
  transform: translateY(-50%);
}

.layer_comm .inner_layer {
  display: flex;
  top: inherit;
  left: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 54vh;
  /* height: 478px; */
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  padding: 30px 0;
  margin: 0 auto;
  position: initial;
}
.layer_comm .coupon_title {
  width: 42%;
  margin: 0 auto 10px auto;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 25px;
  font-size: 20px;
}
.coupon__container {
  overflow: auto;
  width: 100%;
}

.coupon__container .coupon {
  width: 258px;
  height: 102px;
  margin: 0 auto 8px auto;
  cursor: pointer;
}
.coupon.coupon200000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202406/2024festival/festival_coupon_20_on.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon200000.is-download {
  background: url(//res.tutoring.co.kr/res/images/evt/202406/2024festival/festival_coupon_20_off.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon100000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202406/2024festival/festival_coupon_10_on.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon100000.is-download {
  background: url(//res.tutoring.co.kr/res/images/evt/202406/2024festival/festival_coupon_10_off.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon70000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202406/2024festival/festival_coupon_7_on.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon70000.is-download {
  background: url(//res.tutoring.co.kr/res/images/evt/202406/2024festival/festival_coupon_7_off.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon50000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202406/2024festival/festival_coupon_5_on.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon50000.is-download {
  background: url(//res.tutoring.co.kr/res/images/evt/202406/2024festival/festival_coupon_5_off.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon30000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202406/2024festival/festival_coupon_3_on.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon30000.is-download {
  background: url(//res.tutoring.co.kr/res/images/evt/202406/2024festival/festival_coupon_3_off.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon10000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202211/bigseason/big_coupon_1_on.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon10000.is-download {
  background: url(//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_coupon_1_off.png) no-repeat 50%;
  background-size: contain;
}

.coupon_button {
  width: 258px;
  background: #c021c3;
  border-radius: 10px;
  padding: 12px 16px;
  color: #ffff;
  font-size: 15px;
  font-weight: 700;
  margin-top: 10px;
  line-height: 25px;
}
.coupon_button_enabled {
  width: 258px;
  background: #f1f0f5;
  border-radius: 10px;
  padding: 12px 16px;
  color: #d6d3dd;
  font-size: 15px;
  font-weight: 700;
  margin-top: 10px;
  line-height: 25px;
}
.coupon_notice {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  /* position: absolute; */
  background-color: #f1f1f1;
  border-radius: 0 0 20px 20px;
  padding: 20px 24px;
}
.coupon_notice .notice_title {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.coupon_notice .notice_desc {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #666666;
}

.extra_wrapper {
  padding: 10px 0;
  text-align: center;
  /* position: relative;
	top: 740px; */
}
.extra_wrapper .extra_button {
  color: #fff;
  background-color: #121214;
  border-radius: 25px;
  font-size: 25px;
  padding: 2px 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.extra_wrapper .extra_button .close_icon {
  width: 16px;
  height: 16px;
}
.coupon-wrapper {
  margin-bottom: -48px;
  padding: 20px;
  background-color: #f5f5f5;
}
.btmwrap-container {
  display: flex;
  background-color: white;
  justify-content: space-between;
}
.text-impact {
  color: var(--opacity-white, #fff);
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 160% */
  letter-spacing: -0.5px;
}
.text-blur {
  color: var(--opacity-white, rgba(255, 255, 255, 0.6));
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
}
</style>
